<style>
/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

html {
  font-size: 50px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Microsoft Yahei", sans-serif;
}

body * {
  box-sizing: border-box;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}
</style>
<meta
  http-equiv="Cache-Control"
  content="no-cache, no-store, must-revalidate"
/>
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />
<template>
  <div class="flex-col page">
    <div class="flex-col flex-auto group_2 space-y-6">
      <div class="flex-col section">
        <div class="flex-col group_3">
          <div class="flex-col group_4">
            <div class="flex-row group_5 space-x-6">
              <img
                class="self-start image_4"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16799701302489995588.png"
              />
              <div
                class="flex-col justify-start items-end flex-auto relative group_6"
              >
                <div
                  class="flex-col justify-start items-center image-wrapper"
                ></div>
                <span class="text_2 pos_2">您的咨询提交成功，订单受理中</span>
                <span class="text_3 pos_3"
                  >请保持电话畅通，以免错过审核电话</span
                >
              </div>
            </div>
            <div class="flex-col relative section_2">
              <div class="flex-col justify-start text-wrapper">
                <span class="text_4"
                  >以下产品已提交审核，将会有机构顾问与您取得联系，请您保持手机畅通。</span
                >
              </div>
              <div class="flex-row justify-between items-center group_7">
                <div class="flex-row items-center space-x-8">
                  <img
                    class="shrink-0 image_5"
                    :src="
                      model === 'model9'
                        ? require('../../../public/aed_logo.png')
                        : require('../../assets/images/model8/modelLogo.png')
                    "
                  />
                  <span class="font_1 text_5">{{ 安e贷 }}</span>
                </div>
                <span class="font_2 text_6">机构代码：207</span>
              </div>
              <span class="self-start font_1 text_7">{{
                apiProduct.companyName
              }}</span>
              <span class="font_2 text_8"
                >*此额度需要机构/资方电话复审（一小时内）请注意接听电话</span
              >
            </div>
          </div>
        </div>
        <div class="flex-col justify-start section_4">
          <div class="flex-col relative section_3 space-y-28">
            <div class="self-center group_9">
              <span class="font_3"> — </span>
              <span class="text_9">选择我们的理由</span>
              <span class="font_3"> — </span>
            </div>
            <div class="grid">
              <div
                class="flex-row items-start justify-center items-center grid-item space-x-6"
              >
                <img
                  class="shrink-0 image_6"
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16798802086828158497.png"
                />
                <div class="flex-col items-start flex-auto space-y-10">
                  <span class="font_1 text_11">安全</span>
                  <span class="font_2">持牌金融机构合作</span>
                </div>
              </div>
              <div
                class="flex-row justify-center items-center grid-item space-x-6"
              >
                <img
                  class="image_6"
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16798802087396180084.png"
                />
                <div class="flex-col items-start space-y-10">
                  <span class="font_1 text_11">放款</span>
                  <span class="font_2">最快5分钟放款</span>
                </div>
              </div>
              <div
                class="flex-row items-center items-center grid-item space-x-6"
              >
                <img
                  class="shrink-0 image_6"
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16798802087365800338.png"
                />
                <div class="flex-col items-start flex-auto space-y-10">
                  <span class="font_1 text_11">灵活</span>
                  <span class="font_2">无年费 无套路</span>
                </div>
              </div>
              <div
                class="flex-row justify-center items-center grid-item_2 space-x-6"
              >
                <img
                  class="image_6"
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16798802089075515487.png"
                />
                <div class="flex-col items-start space-y-8">
                  <span class="font_1 text_11">额度</span>
                  <span class="font_2">最高可借20万</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col section_5">
        <div
          class="flex-col justify-start items-center button"
          v-on:click="handleDownload"
        >
          <span class="text_14">下载APP查看审核结果({{ timerCount }}s)</span>
        </div>
        <div class="apply_group_10">
          <span class="font_4 text_16">温馨提示：</span>
          <span class="font_4 text_17"><br /></span>
          <span class="font_4 text_15">
            为您提供贷款咨询推荐服务,在使用服务过程中遇到的任何预先收费,网络转账、素要银行卡密码、素要手机验证码行为均有诈骗风险,请保持警惕避免损失。同时您知愿您已授权安信花基于向您推荐货款資询服务之目的,将您的个人信息提供给贷款咨询服务机构,请特别关注机构是否按照您的授权使用您的个人信息并向您提供贷款咨询服务。
          </span>
        </div>
      </div>
    </div>
    <!-- 下载跳转提示 -->
    <div class="van-overlay" v-show="showDownloadPop">
      <img
        class="icon-download"
        src="../../../public/img/register/download_bg.png"
      />
    </div>
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";
window.onpageshow = function () {
  if (
    sessionStorage.getItem("showPopUpView") &&
    sessionStorage.getItem("handleApplyLoan")
  ) {
    window.location.reload();
    sessionStorage.removeItem("handleApplyLoan");
  }
};

export default {
  name: "applyResults",
  data() {
    return {
      model: sessionStorage.getItem("model"),
      default_cattle:
        'this.src="' + require("../../../public/aed_logo.png") + '"', //加载图片报错时处理方法
      apiProduct: {
        imgUrl: "",
        thirdPartyId: "",
        productId: "",
        companyName: "",
        name: "",
        userId: "",
        content: "",
      },
      timer: undefined,
      timerOut: undefined,
      timerCount: 3,
      downAppUrl: "",
      showPopUpView: false,
      cpaProduct: {},
      channel: this.$route.query.source,
      rpChecked: false,
      showDownloadPop: false,
    };
  },

  mounted() {
    this.getDownloadUrl();
  },
  methods: {
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        }
      });
    },
    //API机构和授权书
    getAPIProduct() {
      var that = this;
      API_Common.getUser2WhereApi().then((res) => {
        if (res.code == 200) {
          that.apiProduct = res.data;
        }
      });
    },
    handleDownload() {
      var that = this;
      clearInterval(this.timer);
      clearInterval(this.timerOut);
      if (this.model != "model9") {
        this.showDownloadPop = true;
        setTimeout(function () {
          window.location.href = that.downAppUrl;
        }, 2000);
      } else {
        window.location.href = that.downAppUrl;
      }
    },

    handleProtocol(pageType) {
      window.location.href = "/protocol.html?pageType=" + pageType;
      sessionStorage.removeItem("showPopUpView");
    },
    // 根据渠道code和进行到哪一步查询配置H5弹窗产品权重最大的值
    getCpaProduct() {
      if (this.model != "model9") {
        this.showDownloadPop = true;
      }
      this.timer = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
      this.timerOut = setTimeout(() => {
        clearInterval(this.timer);
        window.location.href = this.downAppUrl;
      }, 3000);
    },
  },
};
</script>
<style scoped>
@import "../../css/model8PopView.css";
</style>
<style scoped lang="css">
.page {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  position: relative;
}
.space-y-12 > *:not(:first-child) {
  margin-top: 0.24rem;
}
.group_2 {
  overflow-y: auto;
}
.space-y-6 > *:not(:first-child) {
  margin-top: 0.12rem;
}
.section {
  padding-top: 0.44rem;
  background-image: linear-gradient(
    128.6deg,
    #50a8ff 11.4%,
    #2f8bff 29.9%,
    #025cf6 81.1%
  );
  border-radius: 0.02rem;
}
.group_3 {
  padding-left: 0.3rem;
  height: 5.1rem;
}
.group_4 {
  height: 4.91rem;
}
.group_5 {
  padding-left: 0.82rem;
}
.image_4 {
  width: 0.68rem;
  height: 0.68rem;
}
.group_6 {
  margin-right: -1.2rem;
}
.image-wrapper {
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16798796167870319869.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 3.44rem;
  margin-left: 3.2rem;
}
.image_3 {
  filter: drop-shadow(0.28rem 0.18rem 0.09rem #1c7bf5);
  width: 3.44rem;
  height: 3.2rem;
}
.text_2 {
  color: #ffffff;
  font-size: 0.32rem;
  font-family: SourceHanSansSC;
  line-height: 0.31rem;
}
.pos_2 {
  position: absolute;
  left: 1.9rem;
  top: 0.6rem;
}
.text_3 {
  color: #ffffff;
  font-size: 0.24rem;
  font-family: SourceHanSansSC;
  line-height: 0.23rem;
}
.pos_3 {
  position: absolute;
  left: 1.9rem;
  top: 1.1rem;
}
.section_2 {
  margin-right: 0.3rem;
  margin-top: -2rem;
  padding-bottom: 0.52rem;
  background-color: #ffffff;
  border-radius: 0.16rem;
}
.text-wrapper {
  padding-top: 0.24rem;
  background-color: #e0edff;
  border-radius: 0.16rem 0.16rem 0px 0px;
  height: 1.08rem;
  border-bottom: solid 0.04rem #eeeeee;
}
.text_4 {
  margin-left: 0.32rem;
  margin-right: 0.4rem;
  color: #5b90db;
  font-size: 0.26rem;
  font-family: PingFangSC;
  line-height: 0.32rem;
}

.group_7 {
  margin-top: 0.4rem;
  padding: 0 0.24rem;
}
.space-x-8 > *:not(:first-child) {
  margin-left: 0.16rem;
}
.image_5 {
  filter: drop-shadow(0px 0.04rem 0.04rem #91919130);
  width: 0.48rem;
  height: 0.48rem;
}
.font_1 {
  font-size: 0.3rem;
  font-family: PingFangSC;
  line-height: 0.4rem;
}
.text_5 {
  font-size: 0.32rem;
  line-height: 0.3rem;
}
.font_2 {
  font-size: 0.24rem;
  font-family: PingFangSC;
  line-height: 0.24rem;
  color: #999999;
}
.text_6 {
  font-size: 0.26rem;
  line-height: 0.26rem;
}
.text_7 {
  margin-left: 0.24rem;
  margin-top: 0.2rem;
  color: #333333;
  font-size: 0.28rem;
}
.text_8 {
  margin-left: 0.24rem;
  margin-top: 0.24rem;
  color: #bbbbbb;
  line-height: 0.24rem;
}
.group_8 {
  margin-right: 0.3rem;
  margin-top: -0.38rem;
  border-radius: 0px 0px 0.16rem 0.16rem;
  height: 0.74rem;
}
.section_4 {
  padding-bottom: 0.1rem;
  background-image: linear-gradient(180deg, #f9f9f900 0%, #f5f8ff 100%);
  border-radius: 0.02rem;
  margin-top: -0.2rem;
}
.section_3 {
  margin: 0rem 0.3rem 0;
  padding: 0.52rem 0 0.28rem;
  background-color: #ffffff;
  border-radius: 0.16rem;
}
.space-y-28 > *:not(:first-child) {
  margin-top: 0.56rem;
}
.group_9 {
  line-height: 0.3rem;
}
.font_3 {
  font-size: 0.42rem;
  font-family: PingFangSC;
  line-height: 0.03rem;
  font-weight: 100;
  color: #065ecb;
}
.text_9 {
  color: #065ecb;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  line-height: 0.32rem;
}
.grid {
  height: 2.58rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.36rem;
  column-gap: 0.36rem;
}
.grid-item {
  padding: 0 0.34rem 0.32rem;
}
.image_6 {
  width: 0.8rem;
  height: 0.8rem;
}
.image_7 {
  margin-top: 0.28rem;
}
.space-y-10 > *:not(:first-child) {
  margin-top: 0.2rem;
}
.text_10 {
  line-height: 0.4rem;
}
.grid-item_2 {
  padding: 0 0.34rem 0.32rem;
}
.space-x-6 > *:not(:first-child) {
  margin-left: 0.12rem;
}
.text_11 {
  line-height: 0.3rem;
}
.grid-item_3 {
  padding: 0 0.34rem 0.32rem;
}
.text_12 {
  line-height: 0.28rem;
}
.space-x-4 > *:not(:first-child) {
  margin-left: 0.08rem;
}
.text_13 {
  margin-top: 0.16rem;
}
.space-y-8 > *:not(:first-child) {
  margin-top: 0.16rem;
}
.section_5 {
  padding: 0.44rem 0.3rem 0.72rem;
  background-color: #f5f8ff;
}
.button {
  padding: 0.3rem 0 0.3rem;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16701366250750358205.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text_14 {
  color: #ffffff;
  font-size: 0.3rem;
  font-family: PingFangSC;
  line-height: 0.39rem;
}
.apply_group_10 {
  margin: 0.44rem 0.08rem 0 0.04rem;
  line-height: 0.26rem;
  font-size: 0px;
}
.font_4 {
  font-size: 0.24rem;
  font-family: PingFangSC;
  line-height: 0.26rem;
  color: #c2c2c2;
}
.text_16 {
  color: #5b90db;
  font-size: 0.22rem;
}
.text_17 {
  font-size: 0.22rem;
}
.text_15 {
  font-size: 0.22rem;
}
.self-center {
  align-self: center;
}
.icon-download {
  width: 6.8rem;
  height: 7.3rem;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
